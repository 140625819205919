<template>
  <iframe src="https://deskki.dev.spacepass.pro/" frameborder="0" allowfullscreen="1" style="width:1290px;height:1000px;border:none;margin:0;padding:0;overflow:hidden;z-index:999999;"></iframe>
</template>

<script>
export default {
  name: "BookingWorkplace"
}
</script>

<style scoped>

</style>
